import Head from 'next/head'

import type { NextPage } from 'next'

const Home: NextPage = () => {
  return (
    <>
      <Head>
        <title>Upvio Forms</title>
        <link rel='icon' href='/favicon.ico' />
      </Head>
    </>
  )
}

export default Home
